import React from 'react'
import Layout from '../components/layout'

export default ({ pageContext, location }) => {
  const { html, frontmatter } = pageContext

  return (
    <Layout {...{ location, frontmatter }}>
      <div className='container'>
        <div dangerouslySetInnerHTML={{ __html: html || '' }} />
      </div>
    </Layout>
  )
}
